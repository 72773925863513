import { Link } from "gatsby"
import { useState } from "react"
// import LanguageFlag from "./LanguageFlag"
import { useContext } from "react"
import ReactCountryFlag from "react-country-flag"
import { jsx } from "theme-ui"

/** @jsx jsx */
import SeoContext from "../../containers/SeoContext"
import { cn } from "../../lib/helpers"
import Icon from "../icons"
import FollowAt from "../social/FollowAt"
// import classNames from "classnames"
// import typography from "../../styles/typography.module.css"
import { branding, mobileNav, nav, root, siteName, slogan, socialHeader, toggleNavButton, topHeader, wrapper } from "./header.module.css"

export const MobileNavMenu = ({mobileNav, navigation, menuSlug, altLangUrl, altLocale, locale}) => {

  return (
    <nav
      aria-label="Main Navigation"
      className={cn(mobileNav)}
      // className="block md:hidden col-start-1 col-span-1 row-start-2 p-5 md:p-auto md:mx-auto md:max-w-md"
    >
      <ul>
        {navigation &&
          navigation.map(({menuSlug, menuTitle}, key) => {
            // console.log('--menuSlug',menuSlug);
            //TODO: bring languages back
            menuSlug.current = menuSlug.current.replace('/en/', '/');

            return (
              <li key={key}>
                <Link to={menuSlug.current} activeClassName="active">
                  {menuTitle}
                </Link>
              </li>
            )
          })}
        {/* <li>
          {altLocale === "en" ? (
            <Link to={altLangUrl}>
              <ReactCountryFlag
                countryCode="GB"
                svg
                style={{
                  width: "2em",
                  height: "1em"
                }}
                title="GB"
                alt="english"
                aria-label="United Kingdom"
              />
            </Link>
          ) : (
            <Link to={altLangUrl}>
              <ReactCountryFlag
                countryCode="ES"
                svg
                style={{
                  width: "2em",
                  height: "1em"
                }}
                title="ES"
                alt="español"
                aria-label="Spain"
              />
            </Link>
          )}
        </li> */}
      </ul>
    </nav>
  )
}

export const DesktopNavMenu = ({
  mobileNav,
  navigation,
  menuSlug,
  altLangUrl,
  altLocale,
  locale,
  desktopNav
}) => {
  const navMenu =
    navigation &&
    navigation.map(({menuSlug, menuTitle}, key) => {
      //TODO: bring languages back
      // console.log('----menuSlug', menuSlug);
      menuSlug.current = menuSlug.current.replace('/en/', '/');
      return (
        <li key={key}>
          <Link to={menuSlug.current} activeClassName="active">
            {menuTitle}
          </Link>
        </li>
      )
    })

  // console.dir("DESKTOP MENUUUUUU")

  return navMenu
}

const Header = ({siteTitle, navigation, altLangUrl, locale, onHideNav, onShowNav, showNav}) => {
  const seo = useContext(SeoContext)
  // TODO: FIX CONTEXT.
  // const altLocale = seo.locale == 'en' ? 'es' : 'en'
  const altLocale = locale == "en" ? "es" : "en"
  // const seoAltLang = seo.altLangUrl
  const [showingMenu, setShowingMenu] = useState(true)
  // console.log('menuSlug', menuSlug)

  return (
    <header className={root}>
      <div className={wrapper}>
        <div id="top-header" className={topHeader}>
          <div id="social-header" className={socialHeader}>
          <FollowAt iconSize={1} className="" />
          </div>
          {/* <div id="social-header" className="pinterest">
            <FollowAt iconSize={1} className="" />
          </div> */}
          {/* <div>
            <a href="tel:+3461302792"><span className={phoneHeader}>(+34) 613 02 72 92</span></a>
            <span>{" / "}</span>
            <a href="tel:+3461302792"><span className={phoneHeader}>(+44) 7580499929</span></a>
          </div> */}

        </div>
        <div id="branding" className={branding}>
          <div>
            <Link className={siteName} to={"/"}>
              {siteTitle}
              <div className={slogan}>Timeless Makeup & Hair</div>
            </Link>
            {/* <Link className={siteName} to={"/" + locale}>
              {siteTitle}
              <div className={slogan}>Natural Makeup & Hair</div>
            </Link> */}
          </div>
        </div>

        <button
          aria-label="Menu"
          className={toggleNavButton}
          // sx={{ variant: "containers.header.toggleNavButton" }}
          onClick={() => setShowingMenu(!showingMenu)}
        >
          <Icon symbol="hamburger" />
        </button>

        {/* <Link
          // className="absolute md:relative top-5 left-0 block md:hidden"
          to={altLangUrl}
        >
          <LanguageFlag langcode={altLocale} />
        </Link> */}
        {/* SHOW MENU WHEN CLICKING BUTTON */}
        {!showingMenu ? (
          <MobileNavMenu
            mobileNav={mobileNav}
            navigation={navigation}
            // menuSlug={menuSlug}
            altLangUrl={altLangUrl}
            altLocale={altLocale}
            locale={locale}
          />
        ) : null}

        <nav aria-label="Main Navigation" className={nav}>
          <ul>
            <DesktopNavMenu
              // mobileNav={mobileNav}
              navigation={navigation}
              // menuSlug={menuSlug}
              altLangUrl={altLangUrl}
              altLocale={altLocale}
              locale={locale}
            />
            {/* <li>
              {altLocale === "en" ? (
                <Link to={altLangUrl}>
                  <ReactCountryFlag
                    countryCode="GB"
                    svg
                    style={{
                      width: "2em",
                      height: "1em"
                    }}
                    title="GB"
                    alt="english"
                    aria-label="United Kingdom"
                  />
                </Link>
              ) : (
                <Link to={altLangUrl}>
                  <ReactCountryFlag
                    countryCode="ES"
                    svg
                    style={{
                      width: "2em",
                      height: "1em"
                    }}
                    title="ES"
                    alt="español"
                    aria-label="Spain"
                  />
                </Link>
              )}
            </li> */}
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
