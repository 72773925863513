import Facebook from "./icons/Facebook"
import Github from "./icons/Github"
import Instagram from "./icons/Instagram"
import Pinterest from "./icons/Pinterest"
import Tumblr from "./icons/Tumblr"
import Twitter from "./icons/Twitter"
import Youtube from "./icons/Youtube"
import {root} from "./social.module.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import extractDomain from "extract-domain"
import PropTypes from "prop-types"
import React from "react"

const getIcon = (domain) => {
  switch (domain) {
    case "facebook.com":
      return Facebook
    case "twitter.com":
      return Twitter
    case "youtube.com":
      return Youtube
    case "instagram.com":
      return Instagram
    case "tumblr.com":
      return Tumblr
    case "github.com":
      return Github
    case "pinterest.com":
      return Pinterest
    default:
  }
}

const FollowAt = (props) => {
  const {links, iconSize, className} = props

  return (
    <ul className={root}>
      {links.map((link) => {
        const socialMedia = String(extractDomain(link)).toLowerCase()
        const icon = getIcon(socialMedia)
        return (
          <li key={socialMedia}>
            <a key={socialMedia} href={link} aria-label={icon.iconName} className={`${className}`}>
              <FontAwesomeIcon icon={icon} size={`${iconSize}x`} />
            </a>
          </li>
        )
      })}
    </ul>
  )
}

FollowAt.propTypes = {
  links: PropTypes.array.isRequired,
  iconSize: PropTypes.number
}

// TODO: bring data from Sanity
FollowAt.defaultProps = {
  iconSize: 2,
  links: [
    // "https://twitter.com/agatajuliamua",
    "https://www.facebook.com/agatajuliamua",
    "https://www.instagram.com/agatajuliamua",
    "https://www.pinterest.com/agatajuliamua"
  ]
}

export default FollowAt
