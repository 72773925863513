/** @jsx jsx */
import LocaleContext from "../lib/locale-context";
import {footer, preFooter, mainFooter, footerMenu, footerLinks} from "./footer.module.css"
import InstaBlock from "./instagram-block"
import Header from "./navigation/header"
import {Link} from "gatsby"
import {useContext, Fragment, useEffect, useState} from "react"
import {jsx} from "theme-ui"

const Layout = (props) => {
  const {language, setLanguage} = useContext(LocaleContext)
  const {
    children,
    companyInfo,
    onHideNav,
    onShowNav,
    showNav,
    siteTitle,
    navigation,
    footerNavigation,
    altLangUrl
  } = props

  // console.log('----header', navigation)
  const footerNavigationMenu =
    footerNavigation &&
    footerNavigation.map(({menuSlug, menuTitle}, key) => {
      //TODO:bring lang url prefixes.
      menuSlug.current = menuSlug.current.replace("/en/", "/")
      // console.log("footer-menuSlug", menuSlug)
      return (
        <li key={key}>
          <Link className={footerLinks} to={menuSlug.current} activeClassName="active">
            {menuTitle}
          </Link>
        </li>
      )
    })

  // console.log("NAVIGATION", navigation)
  // console.log("footerNavigation", footerNavigation)

  return (
    <Fragment>
      <Header
        className="w-full mb-10"
        siteTitle={siteTitle}
        onHideNav={onHideNav}
        onShowNav={onShowNav}
        showNav={showNav}
        locale={language}
        altLangUrl={altLangUrl}
        navigation={navigation}
      />
      <div>{children}</div>
      <footer className={footer}>
        <div className={preFooter}>
          <InstaBlock />
        </div>
        <div className={mainFooter}>
          {footerNavigationMenu && <ul className={footerMenu}>{footerNavigationMenu}</ul>}

          <div>
            {/* <FollowAt iconSize={2} className={social} /> */}
            <div>
              {companyInfo && (
                <div>
                  {companyInfo.name} © {new Date().getFullYear()}
                  {/* <br /> */}
                  {/* {companyInfo.address1}
                <br /> */}
                  {companyInfo.address2 && (
                    <span>
                      {companyInfo.address2}
                      {/* <br /> */}
                    </span>
                  )}
                  {companyInfo.zipCode} {companyInfo.city}
                  {companyInfo.country && <span>, {companyInfo.country}</span>}
                </div>
              )}
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  )
}

export default Layout