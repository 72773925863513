import {instaBlockPosts, instaBlock} from "./instagram.module.css"
import React from "react"
import {useEffect, useState} from "react"

export default function InstaBlock() {
  const [posts, setPosts] = useState([])
  const urlFetch = `https://graph.instagram.com/me/media?fields=id,permalink,media_type,media_url,thumbnail_url,caption&ratio=1,1&width=230&access_token=${process.env.GATSBY_INSTAGRAM_ACCESS_TOKEN}&limit=4`

  useEffect(() => {
    fetch(urlFetch)
      .then((res) => res.json())
      .then((data) => {
        setPosts(data.data)
      })
      .catch((err) => {
        setPosts([])
      })
  }, [])

  return (
    <div className={instaBlock}>
      {posts?.length ? <h3>Instagram</h3> : null}
      <div className={instaBlockPosts}>
        {Array.isArray(posts) &&
          posts.map((gram) => {
            let mediaUrl = gram.thumbnail_url ? gram.thumbnail_url : gram.media_url
            return (
              <a href={gram.permalink} key={gram.id} target="_blank" rel="noreferrer">
                <img
                  src={`https://images.weserv.nl/?url=${encodeURIComponent(
                    mediaUrl
                  )}&w=230&h=230&fit=cover`}
                  alt={gram.caption}
                />
              </a>
            )
          })}
      </div>
    </div>
  )
}