import Layout from "../containers/layout"
import {createLocaleTextGetter} from "../lib/create-locale"
// import getLocalizedContent from '../lib/create-locale'
import LocaleContext from "../lib/locale-context"
import {graphql, useStaticQuery} from "gatsby"
import React from "react"

const query = graphql`
  query NavigationMainMenuQuery {
    navigation: sanityNavigationMenu(title: {eq: "MainNav"}) {
      _rawMenuItem
      id
      menuItem {
        menuTitle {
          es
          en
          _type
        }
        menuSlug {
          es {
            current
          }
          en {
            current
          }
          _type
        }
      }
    }

    footerNavigation: sanityNavigationMenu(title: {eq: "Footer Menu"}) {
      _rawMenuItem
      id
      menuItem {
        menuTitle {
          es
          en
          _type
        }
        menuSlug {
          es {
            current
          }
          en {
            current
          }
          _type
        }
      }
    }
  }
`

const LayoutContainer = ({locale, hero, ...rest}) => {
  const {navigation, footerNavigation} = useStaticQuery(query)
  const getLocalizedContent = createLocaleTextGetter(locale)
  return (
    <LocaleContext.Provider value={{language: locale}}>
      <Layout
        navigation={getLocalizedContent(navigation._rawMenuItem)}
        footerNavigation={getLocalizedContent(footerNavigation._rawMenuItem)}
        {...{
          ...rest,
          homeLink: locale,
          navigation: getLocalizedContent(navigation._rawMenuItem),
          footerNavigation: getLocalizedContent(footerNavigation._rawMenuItem)
        }}
      />
    </LocaleContext.Provider>
  )
}
export default LayoutContainer
