export const createLocaleTextGetter = (languageCode) => {
  const languages = [languageCode, "en"] // last language in array is default;
  const localize = (value) => {
    // console.log(value)
    if (Array.isArray(value)) {
      return value.map((v) => localize(v, languages))
    } else if (value && typeof value === "object") {
      if (value._type && /^locale[A-Z]/.test(value._type)) {
        // console.log(value)
        // if (/^localeSlug/.test(value._type)) {
        //   const language = languages.find(lang => {
        //     // console.dir(value[lang].current)

        //     return value[lang].current
        //   })
        //   return value[language].current
        // } else {
        const language = languages.find((lang) => {
          // console.log(value[lang])
          return value[lang]
        })
        return value[language]
        // }
      }

      return Object.keys(value).reduce((result, key) => {
        result[key] = localize(value[key], languages)
        return result
      }, {})
    }
    return value
  }

  return localize
}
