import SeoContext from "../containers/SeoContext";
// import PropTypes from 'prop-types'
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { StaticQuery, graphql, push } from "gatsby";
import React, { useContext } from "react";
import Helmet from "react-helmet";


//

const pathname = typeof window !== "undefined" ? window.location.pathname : ""
const origin = typeof window !== "undefined" ? window.location.origin : ""

function SEO({description, lang = "en", meta = [], keywords = [], title, ...props}) {
  const localeAlternate = lang == "en" ? "es_ES" : "en_GB"
  const canonicalAlternate = lang == "en" ? "es" : "en"
  const locale = lang == "en" ? "en_GB" : "es_ES"
  const canonical = pathname
  // const canonical = window.location.href
  const seo = useContext(SeoContext)
  // console.log('canonical', canonical)

  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        if (!data.site) {
          return
        }
        const metaDescription = seo?.description
          ? seo.description
          : "" /* || description || data.site.description */
        const metaTitle = seo?.title ? seo.title : "" /* || title || data.site.title */

        const metaImage = seo?.image?.asset
          ? imageUrlFor(buildImageObj(seo.image)).width(1200).url()
          : null

        const metadata = []
        metaDescription ? metadata.push({name: "description", content: metaDescription}) : null
        metaTitle ? metadata.push({name: "og:title", content: metaTitle}) : null
        metaTitle ? metadata.push({name: "twitter:title", content: metaTitle}) : null
        metaDescription ? metadata.push({name: "og:description", content: metaDescription}) : null
        metaDescription
          ? metadata.push({name: "twitter:description", content: metaDescription})
          : null
        locale ? metadata.push({name: "og:locale", content: locale}) : null
        // localeAlternate
        //   ? metadata.push({name: "og:locale:alternate", content: localeAlternate})
        //   : null
        metaImage ? metadata.push({property: "og:image", content: metaImage}) : null
        canonical ? metadata.push({property: "og:url", content: canonical}) : null

        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={metaTitle}
            titleTemplate={metaTitle === data.site.title ? "%s" : `%s | ${data.site.title}`}
            meta={[
              ...metadata,
              {
                property: "og:type",
                content: "website"
              },
              {
                name: "twitter:card",
                content: "summary"
              },
              {
                name: "twitter:creator",
                content: "agatajuliamua"
              }
            ]
              .concat(
                keywords && keywords.length > 0
                  ? {
                      name: "keywords",
                      content: keywords.join(", ")
                    }
                  : []
              )
              .concat(meta)}
          >
            {origin && <link rel="canonical" hrefLang={locale} href={origin + "" + canonical} />}
            {/* {origin && (
              <link rel="alternate" hrefLang={localeAlternate} href={origin + props.altLangUrl} />
            )} */}
          </Helmet>
        )
      }}
    />
  )
}

// SEO.defaultProps = {
//   lang: 'en',
//   meta: [],
//   keywords: []
// }

// SEO.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.array,
//   keywords: PropTypes.arrayOf(PropTypes.string),
//   title: PropTypes.string.isRequired
// }

export default SEO

const detailsQuery = graphql`
  query SEOQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      description
      keywords
      author
    }
  }
`